import React from 'react';
import PromoteCta from '../components/common/PromoteCta';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import regalImg from '../images/regal-project.png';

function AboutRegal() {
  return (
    <Layout>
      <SEO
        keywords={[`regal project`, `ireland`, `focus`, `lone parents`]}
        title='About REGAL '
      />
      <section
        id='about'
        className=' w-full flex flex-wrap md:flex-row md:flex-no-wrap p-8  max-w-4xl py-16 md:py-32 space-x-6'
      >
        <div className='w-full md:w-1/3 '>
          <img src={regalImg} />
        </div>

        <div className=' md:w-2/3     md:px-0'>
          <header className='text-4xl leading-none md:text-6xl font-serif text-left mb-4 mt-4 md:mt-0 '>
            <h2>
              <span className='alt-heading-bg'> About</span> <br></br>Regal
            </h2>
          </header>
          <div className='content  mx-auto space-y-4 md:text-xl'>
            <p>
              The REGAL project investigates the possibilities of achieving a
              better work-life balance for vulnerable groups. The existing
              measures to achieve a better balance are based on a ‘normal’ life
              situation: families who have regular work and are not in a
              precarious situation. These Work-Life-Balance (WLB) measures aim
              to achieve a high level of employability among women and men
              through a good balance between the needs of the family and the
              requirements of work. WLB concentrates on the time shortages of
              families (and especially women) to reconcile work and life.
            </p>

            <p>
              The REGAL project departs from the precarious situation of
              families. The precarious situation is an additional barrier for
              achieving employment and a balanced Work-Life Balance. A
              precarious situation is determined in particular by poorer working
              conditions (irregular work), a poor housing situation and a poor
              financial situation. All these elements can be an obstacle to find
              a good WLB.
            </p>
            <p>
              REGAL examines how measures can be taken within the WLB to ensure
              a better balance for these vulnerable families and to give them an
              incentive to be able to work.
            </p>
            <a
              className='underline faith-link '
              href='https://regalproject.eu/'
            >
              REGAL – Regaining life for precarious women at work{' '}
            </a>
          </div>
        </div>
      </section>
      <section className='grid grid-cols-2 w-full'>
        <Link className='col-span-2 md:col-span-1' to='/creating-faith'>
          <div className='col-span-2 bg-faith-green '>
            <div className='py-16 md:py-32'>
              <h4 className='text-2xl md:text-5xl text-center font-bold text-white'>
                How We Created Faith
              </h4>
            </div>
          </div>
        </Link>
        {/* About Regal */}
        <Link className='col-span-2 md:col-span-1' to='/about-focus'>
          <div className='bg-faith-yellow '>
            <div className='py-16 md:py-32'>
              {' '}
              <h5 className='text-2xl md:text-5xl text-center font-bold  text-white'>
                About Focus Ireland
              </h5>
            </div>
          </div>
        </Link>
      </section>

      <PromoteCta />
    </Layout>
  );
}

export default AboutRegal;
