import React from 'react';
import RegalImage from './../images/regal-eu.png';
import EuImage from './../images/eu-project.png';

function footer() {
  return (
    <>
      <footer className='bg-white'>
        <nav className='flex flex-wrap md:flex-no-wrap md:flex-row justify-start items-center space-x-2 max-w-3xl px-4 py-8 mx-auto text-sm md:p-8'>
          <img src={RegalImage} className=' h-10 object-scale-down' />
          <img src={EuImage} className=' h-10 object-scale-down  ' />

          <p className='text-black  '>
            TestingFaith.ie is part of a research project for{' '}
            <a href='https://regalproject.eu/' className='underline font-bold'>
              REGAL
            </a>
            , funded by the European Union&apos;s Rights Equality and
            Citizenship Programme (2014-2020)
          </p>
        </nav>
      </footer>
    </>
  );
}

export default footer;
