import React from 'react';
import DiaryCard from '../illustrations/DiaryCard';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

function PromoteCta() {
  return (
    <div className='w-full col-span-2 bg-faith-purple px-8 '>
      <div className='py-16 md:py-32 flex flex-col-reverse md:flex-row mx-auto max-w-4xl text-left'>
        <div className='md:w-1/2'>
          <h4 className='text-4xl md:text-6xl font-serif  leading-none '>
            Faith deserves to be heard
          </h4>
          <p className='py-4'>
            We think that Faith and all the people she represents throughout
            Ireland deserve to be heard. You can play your part by sharing this
            project on your favourite social media channel.
          </p>
          <div className='space-x-2 text-xs md:text-base'>
            <FacebookShareButton
              className='faith-btn  '
              url='https://testingfaith.ie'
            >
              <span>Facebook</span>
            </FacebookShareButton>

            <TwitterShareButton
              className='faith-btn '
              url='https://testingfaith.ie'
            >
              <span>Twitter</span>
            </TwitterShareButton>
            <WhatsappShareButton
              className='faith-btn '
              url='https://testingfaith.ie'
            >
              <span>WhatsApp</span>
            </WhatsappShareButton>
          </div>
        </div>
        <div className='md:w-2/5 md:ml-auto my-8 md:my-0'>
          <div className=' bg-white w-full block p-4 rounded-lg mb-4'>
            <div className='bg-faith-purple p-4 w-auto'>
              <DiaryCard className='w-auto' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PromoteCta;
