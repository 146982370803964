import PropTypes from 'prop-types';
import React from 'react';
import Header from './header';
import Footer from './footer';

function Layout({ children, bg }) {
  return (
    <div
      className={`${
        bg ? bg : `bg-white`
      } flex flex-col  font-sans antialiased text-gray-900`}
    >
      <Header />
      <main className='flex flex-col justify-center items-center  w-full  md:text-xl'>
        {children}
      </main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
